

























import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IPatient } from '@/interfaces';
import { readPatients } from '@/store/patients/getters';
import { dispatchGetPatients } from '@/store/patients/actions';

@Component
export default class Patients extends Vue {
  public headers = [
    {
      text: 'Nickname',
      sortable: true,
      value: 'first_name',
      align: 'left',
    },
    {
      text: 'Registration Date',
      sortable: true,
      value: 'registration_date',
      align: 'left',
    },
    {
      text: 'Status',
      sortable: true,
      value: 'status',
      align: 'left',
    },
  ];

  public async mounted() {
    await dispatchGetPatients(this.$store);
  }

  get patients() {
    return readPatients(this.$store);
  }
}
